import * as yup from 'yup';

export const schema = yup.object().shape({
  farmName: yup.string().required('Campo obrigatório.'),
  farmOwnerName: yup.string().required('Campo obrigatório.'),
  farmCity: yup.string().required('Campo obrigatório.'),
  purchasedAt: yup.string().required('Campo obrigatório.'),
  priceByArroba: yup.string().required('Campo obrigatório.'),
  cattleModality: yup.object().shape({
        value: yup.string(),
        label: yup.string(),
    }),
});
