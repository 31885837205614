import React, { useState } from 'react';
import useGetList from 'services/hooks/useGetList';
import TablePaginator from 'components/Paginator';
import { Modal } from 'components/Modals/Modal';
import PageCard from 'components/Cards/PageCard';
import { Container } from '../../../assets/shared/styles';
import CardTableList from 'components/Cards/CardTableList';
import ModalForm from './ModalForm';
import ModalViewWeight from './ModalViewWeight';
import { FaEye, FaWeightHanging } from 'react-icons/fa';
import UploadFile from 'components/Modals/UploadFile';
import SearchEngine from 'components/Search/SearchEngine';
import { useSelector } from 'react-redux';
import { FiCheckCircle, FiPlus, FiTrash, FiXCircle } from 'react-icons/fi';
import useDelete from 'services/hooks/useDelete';

const PurchaseDescription = ({ color = 'light' }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [openViewWeight, setOpenViewWeight] = useState(false);
  const [selected, setSelected] = useState({});
  const search = useSelector((state) => state.filter.value);

  // get LIST
  const {
    data: purchaseDescriptions,
    getItems,
    isLoading: isLoadingList,
    meta,
  } = useGetList({
    page: currentPage,
    route: `purchase-description/list`,
    search,
  });

  // DELETE
  const { apiDelete, isLoading: isLoadingDelete } = useDelete({
    errorMessage: 'falha ao apagar bloco de compra, tente novamente mais tarde',
    successMessage: 'bloco de compra removido com sucesso',
    onSuccess: getItems,
  });

  const isLoading = isLoadingList;

  return (
    <PageCard
      color={color}
      headerContent={
        <>
          <SearchEngine fields={['search', 'farmOwnerName', 'farmName', 'farmCity', 'cattleModality', 'date']} />
        </>
      }
    >
      <Modal
        title={`${selected?.identifier ? `Detalhes: ${selected.farmOwnerName}` : "Nova bloco de compra"}`}
        show={openForm}
        setShow={setOpenForm}
      >
        <ModalForm
          onSuccess={() => getItems()}
          selected={selected}
        />
      </Modal>
      <Modal
        title={`${selected?.identifier ? `Detalhes dos pesos: ${selected.farmOwnerName}` : "Nova bloco de compra"}`}
        show={openViewWeight}
        setShow={setOpenViewWeight}
      >
        <ModalViewWeight
          onSuccess={() => getItems()}
          selected={selected}
        />
      </Modal>
      <CardTableList title={'Bloco de Compra'} color={color}>
        <div className="flex flex-wrap float-right mb-4">
          <div className="w-full">
            <button
              type="button"
              title="Adicionar nova bloco de compra"
              className="p-2 mr-3 auto rounded bg-black"
              onClick={() => {
                setSelected({});
                setOpenForm(true);
              }}
            >
              <FiPlus size={15} color="#fff" />
            </button>
          </div>
        </div>
        <Container className="w-full overflow-auto">
          <table className="items-center table-auto w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={`py-3 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Nome da fazenda
                </th>
                <th
                  className={`py-3 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Nome do proprietário
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Cidade
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Quantidade de Cabeças
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Categoria
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Preço por arroba
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Preço total
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Ações
                </th>
              </tr>
            </thead>

            <tbody>
              {purchaseDescriptions?.map(
                (purchaseDescription) => (
                  <tr key={purchaseDescription.identifier}>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.farmName}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.farmOwnerName}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.farmCity}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.cattleUnityAmount}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.cattleModality}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.priceByArroba?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </span>
                    </td>
                    <td className="text-xs">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {purchaseDescription?.totalPrice?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </span>
                    </td>
                    <td className="text-xs">
                      {purchaseDescription.purchaseDescriptionItems.length > 0 && (
                        <>
                          <button
                            type="button"
                            className="mr-2 p-2 rounded bg-lightBlue-500"
                            onClick={() => {
                              setSelected(purchaseDescription);
                              setOpenForm(true);
                            }}
                          >
                            <FaEye size={15} color="#fff" />
                          </button>
                          <button
                            type="button"
                            className="mr-2 p-2 rounded bg-lightGreen-500"
                            onClick={() => {
                              setSelected(purchaseDescription);
                              setOpenViewWeight(true);
                            }}
                          >
                            <FaWeightHanging size={15} color="#fff" />
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        className={`p-2 rounded bg-red-500 ${purchaseDescription.purchaseDescriptionItems.length === 0 ? "ml-20" : ""}`}
                        onClick={() => {
                          apiDelete({
                            name,
                            route: `/purchase-description/${purchaseDescription.identifier}/remove`,
                          });
                        }}
                      >
                        <FiTrash size={15} color="#fff" />
                    </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Container>
        <TablePaginator
          data={purchaseDescriptions}
          emptyMessage="Sem informações para listar"
          isLoading={isLoading}
          meta={meta}
          setCurrentPage={setCurrentPage}
        />
      </CardTableList>
    </PageCard>
  );
};

export default PurchaseDescription;
