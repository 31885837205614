import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

import useGet from 'services/hooks/useGet';
import api from 'services/api';
import { getValuesFormatted } from 'utils/format/formikValues';
import Password from 'components/Forms/UserForm/Password';
import Tabs from 'components/Tabs';
import { schema } from './schema';
import { ErrorMessage } from 'formik';
import CurrencyInput from "react-currency-input";
import { FaPlus } from "react-icons/fa";
import { FiTrash } from 'react-icons/fi';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
import moment from 'moment';
import Select from 'react-select'

registerLocale('pt', pt)

function ModalForm({
  closeModal,
  onSuccess = () => {},
  selected,
}) {
  const [itens, setItens] = useState([]);
  const { data: metaField, getEntity } = useGet({
    route: `/purchase-description/${selected.identifier}/show`,
    isAutomatic: false,
  });

  useEffect(() => {
    if (selected.identifier) {
      var aux = [];
      selected.purchaseDescriptionItems.map(item => {
        if (Object.keys(item).length > 0) {
          aux.push(item.weight);
        }
      })

      setItens(aux);
      getEntity();
    }
  }, []);

  const onSubmit = ({ values, setSubmitting }) => {
    api
      .post('purchase-description/new', values)
      .then((e) => {
        toast.success(`Bloco de compra cadastrada com sucesso!`);
        onSuccess();
        closeModal();
      })
      .catch(() => {
        setSubmitting(false);
      })
      .finally(() => {
        if (selected.identifier) {
          setSubmitting(false);
        }
      });
  };

  const addInput = (e) => {
    e.preventDefault();
    setItens([...itens, ""]);
  }

  const handleChangeItem = (floatvalue, index) => {
    itens[index] = floatvalue;
    setItens([...itens]);
  }

  const handleRemoveInputItem = (e, position) => {
    e.preventDefault();
    setItens([...itens.filter((_, index) => index !== position)]);
  }

  const labelClassName =
    'block uppercase text-blueGray-600 text-xs font-bold mb-2 w-min whitespace-nowrap ';

  return (
    <>
      {!selected.identifier ? (
        <>
          <Formik
            initialValues={{
              farmName: '',
              farmOwnerName: '',
              farmCity: '',
              purchasedAt: new Date(),
              priceByArroba: '',
              cattleModality: { label: 'BEZERRO', value: 'BEZERRO' },
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              let itensToSend = [];
              itens.map(item => {
                if (item > 0) {
                  itensToSend.push({
                    weight: item
                  })
                }
              })

              let newValues = {
                ...values,
                purchaseDescriptionItems: itensToSend
              }

              var aux = getValuesFormatted(newValues);

              const valuesFormatted = {
                ...aux,
                purchasedAt: moment(values.purchasedAt).format("YYYY-MM-DD")
              }

              onSubmit({ values: valuesFormatted, setSubmitting });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              setFieldError,
              isSubmitting,
            }) => (
              <form
                style={{ minHeight: 210, maxWidth: 600 }}
                className="flex flex-wrap md:w-full"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="farmName">
                      Nome da fazenda
                    </span>
                    <input
                      autoComplete="nope"
                      value={values.farmName}
                      name="farmName"
                      id="farmName"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="farmName"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="farmOwnerName">
                      Nome do proprietário
                    </span>
                    <input
                      autoComplete="nope"
                      value={values.farmOwnerName}
                      name="farmOwnerName"
                      id="farmOwnerName"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="farmOwnerName"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="farmCity">
                      Município
                    </span>
                    <input
                      autoComplete="nope"
                      value={values.farmCity}
                      name="farmCity"
                      id="farmCity"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="farmCity"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="priceByArroba">
                      Preço por arroba
                    </span>
                    <CurrencyInput
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 form-control"
                        type="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="2"
                        onChange={(maskedvalue, floatvalue, event) => {
                            handleChange(event);
                            setFieldValue("priceByArroba", floatvalue)
                        }}
                        onBlur={handleBlur}
                        allowEmpty={false}
                        value={values.priceByArroba}
                        name="priceByArroba"
                    />
                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="priceByArroba"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="cattleModality">
                      Categoria
                    </span>
                    <Select
                      selected={{ label: "BEZERRO", value: "BEZERRO" }}
                      handleBlur={handleBlur}
                      name="cattleModality"
                      value={values.cattleModality || { label: "BEZERRO", value: "BEZERRO" }}
                      placeholder=""
                      value={values.cattleModality}
                      onChange={(select) => {
                            setFieldValue("cattleModality", select)
                        }}
                      options={[
                          { value: 'BEZERRO', label: 'BEZERRO'},
                          { value: 'BEZERRA', label: 'BEZERRA'},
                          { value: 'GARROTE', label: 'GARROTE'},
                          { value: 'NOVILHA', label: 'NOVILHA'},
                          { value: 'BOI', label: 'BOI' },
                          { value: 'VACA', label: 'VACA' },
                      ]}
                    />
                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="cattleModality"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span className={labelClassName} htmlFor="purchasedAt">
                      Data de compra
                    </span>
                    <DatePicker
                      locale="pt"
                      selected={values.purchasedAt && new Date(values.purchasedAt)|| new Date()}
                      dateFormat="dd/MM/yyyy"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="purchasedAt"
                      onChange={date => setFieldValue("purchasedAt", date)}
                    />
                    <ErrorMessage
                      component="p"
                      className="text-red-500 mb-4 font-light text-xs"
                      name="purchasedAt"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4 mb-5 flex">
                  <div className="relative w-6/12 mb-3">
                    <div className="flex justify-between mt-4 float-left">
                        {itens.length} pesos inseridos
                    </div>
                  </div>
                  <div className="relative w-6/12 mb-3">
                    <div className="flex justify-between mt-4 float-right">
                      <label>
                        Adicionar novo peso
                        <button
                            className="cursor-pointer inline-block bg-green-400 p-1 rounded text-white ml-3 text-sm"
                            onClick={addInput}>
                            <FaPlus />
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap md:w-full overflow-x-auto" style={ itens.length > 5 ? { height: 300 } : {} }>
                  {itens.map((item, index) => (
                    <div key={index} className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <div className="flex">
                          <span className={labelClassName} htmlFor="item">
                            {`Peso em kg por cabeça ${index + 1}`}
                          </span>
                          <button className="mb-3 ml-2" onClick={(e) => handleRemoveInputItem(e, index)}>
                            <FiTrash size={15} color="red" />
                          </button>
                        </div>
                        <CurrencyInput
                          value={item}
                          name={item}
                          id={`item ${index + 1}`}
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(maskedvalue, floatvalue, event) => {
                              handleChangeItem(floatvalue, index)
                          }}
                          decimalSeparator="."
                          thousandSeparator="."
                          precision="3"
                          onBlur={handleBlur}
                          allowEmpty={false}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end self-end w-full mt-10">
                  <button
                    disabled={isSubmitting}
                    className="bg-black text-white text-sm font-bold p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      <>{'ADICIONAR'}</>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <hr />
          <strong className="px-4">Detalhes do preenchimento</strong>
          <div className="flex flex-wrap sm:w-full mt-5">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Nome da fazenda
                </span>
                <p>{selected.farmName}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Nome do Proprietário
                </span>
                <p>{selected.farmOwnerName}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Município
                </span>
                <p>{selected.farmCity}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Data
                </span>
                <p>{moment(selected.purchaseAt).format("YYYY-MM-DD")}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Categoria
                </span>
                <p>{selected.cattleModality}</p>
              </div>
            </div>
          </div>
          <hr />
          <strong className="px-4">Resultados</strong>
          <div className="flex flex-wrap sm:w-full mt-5">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Qtd de Cabeças
                </span>
                <p>{selected.results['qqtCabeca']?.toFixed(2)}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Qtd de Kg Total
                </span>
                <p>{selected.results['pesoTotal']?.toFixed(2)} KG</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Qtd de Kg Médio
                </span>
                <p>{selected.results['pesoMedio']?.toFixed(2)} KG</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Qtd de @ Total
                </span>
                <p>{selected.results['pesoArrobaTotal']?.toFixed(2)} @</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Qtd de @ Médio
                </span>
                <p>{selected.results['pesoArrobaMedio']?.toFixed(2)} @</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  R$ Compra Total
                </span>
                <p>{selected.results['compraTotal']?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <span className={labelClassName} htmlFor="farmName">
                  Compra p/ Cab
                </span>
                <p>R$ {selected.results['compraPorCabeca']?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ModalForm;
