import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function AdminOptions() {
  const { pathname, search } = useLocation();
  const route = pathname + search;

  return (
    <>
      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Usuários
      </h6>

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/users/admin'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/users/admin"
          >
            <i
              className={`fas fa-user mr-2 text-sm ${
                route === '/admin/users/admin'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Administradores
          </Link>
        </li>
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/users/buyer'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/users/buyer"
          >
            <i
              className={`fas fa-user mr-2 text-sm ${
                route === '/admin/users/buyer'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Compradores
          </Link>
        </li>
      </ul>

      <hr className="my-4 border-b-1 border-gray-300" />

      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Termos e politicas
      </h6>

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/policy/terms'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/policy/terms"
          >
            <i
              className={`fas fa-clipboard-list mr-2 text-sm ${
                route === '/admin/policy/terms'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Termos de Uso
          </Link>
        </li>
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/policy/privacy'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/policy/privacy"
          >
            <i
              className={`fas fa-clipboard-list mr-2 text-sm ${
                route === '/admin/policy/privacy'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Política de Privacidade
          </Link>
        </li>
      </ul>

      <hr className="my-4 border-b-1 border-gray-300" />

      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Dados
      </h6>
      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/meta-field'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/meta-field"
          >
            <i
              className={`fas fa-clipboard-list mr-2 text-sm ${
                route === '/admin/meta-field'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Listagem
          </Link>
        </li>
      </ul>
      <hr className="my-4 border-b-1 border-gray-300" />
      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Bloco de Compra
      </h6>
      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/purchase-description'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/purchase-description"
          >
            <i
              className={`fas fa-clipboard-list mr-2 text-sm ${
                route === '/admin/purchase-description'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Listagem
          </Link>
        </li>
      </ul>
    </>
  );
}
