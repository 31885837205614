import React, { useEffect, useState } from 'react';
import useGet from 'services/hooks/useGet';

function ModalViewWeight({
  closeModal,
  onSuccess = () => {},
  selected,
}) {
  const { data: metaField, getEntity } = useGet({
    route: `/purchase-description/${selected.identifier}/show`,
    isAutomatic: false,
  });

  return (
    <>
      <strong className="px-4">Detalhes do preenchimento dos pesos</strong>
      <div
        className="flex flex-wrap sm:w-full mt-5"
        style={{ minHeight: 210, maxWidth: 600 }}
      >
        {selected.purchaseDescriptionItems.map((item, index) => {
          return (
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <p><strong>{`Peso ${index + 1}`}</strong></p>
                <p>{item.weight} KG</p>
                <hr/>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
}

export default ModalViewWeight;
